import { isEmpty, isInteger, isNil } from 'lodash'
import cookie from '@tenant/utils/cookie'
import { STORAGE } from '@tenant/utils/constants'
import * as Sentry from '@sentry/vue'
import posthog from 'posthog-js'

export type Role = {
    id: string
    name: string
    permissions: {
        name: string
    }[]
}

export type User = {
    id: string
    full_name?: string
    first_name?: string
    last_name?: string
    email?: string
    email_verified_at?: string
    two_factor_activated?: boolean
    user_hash?: boolean
    roles: Role[]
}

export type Company = {
    id: string
    expired_subscription?: string
    trial_ends?: string
    subscription_status?: string
}

export type Tenant = {
    id: string
    name: string
}

export type AuthState = {
    central_access_token: string | null
    tenant_access_token: string | null
    tenant_id: string | null
    tenants: Tenant[] | null
    user: User | Record<any, any>
    company: Company | null
    centralUser: User | Record<any, any>
    permissions: string[]
}

export default {
    state: (): AuthState => ({
        central_access_token: null,
        tenant_access_token: null,
        tenant_id: null,
        tenants: null,
        user: {},
        company: null,
        centralUser: {},
        permissions: [],
    }),

    mutations: {
        setUser(state: AuthState, user: User | Record<any, any>) {
            state.user = user
        },

        setCompany(state: AuthState, company: Company | null) {
            state.company = company
        },

        setPermissions(state: AuthState, permissions: string[]) {
            state.permissions = permissions
        },

        setCentralUser(state: AuthState, centralUser: User | Record<any, any>) {
            Sentry.setUser(
                centralUser
                    ? { id: centralUser.id, email: centralUser.email }
                    : null
            )

            state.centralUser = centralUser
        },

        setTenants(state: AuthState, tenants: Tenant[] | null) {
            state.tenants = tenants
        },

        setTenantToken(state: AuthState, tenantToken: string | null) {
            state.tenant_access_token = tenantToken
        },

        setCentralToken(state: AuthState, centralToken: string | null) {
            state.central_access_token = centralToken
        },

        setTenantId(state: AuthState, tenantId: string | null) {
            state.tenant_id = tenantId
        },
    },

    getters: {
        hasTenantToken(state: AuthState) {
            return (
                !isEmpty(state.tenant_access_token) && !isEmpty(state.tenant_id)
            )
        },

        hasCentralToken(state: AuthState) {
            return !isEmpty(state.central_access_token)
        },

        hasCentralRoles(state: AuthState) {
            return state.centralUser && state.centralUser.roles?.length > 0
        },

        user(state: AuthState) {
            return state.user
        },

        centralUser(state: AuthState) {
            return state.centralUser
        },

        emptyRoles(state: AuthState) {
            return isEmpty(state.centralUser?.roles || [])
        },

        hasCentralRole: (state: AuthState) => (role: string) => {
            const centralUserRoles: Role[] = state.centralUser?.roles || []
            if (isEmpty(centralUserRoles)) {
                return false
            }

            return centralUserRoles.some((i) => i.name === role)
        },

        tenants(state: AuthState) {
            return state.tenants
        },

        definedTenants(state: AuthState) {
            return !isNil(state.tenants)
        },

        isActiveUser(state: AuthState) {
            return !isEmpty(state.centralUser?.email_verified_at)
        },

        userHash(state: AuthState) {
            return state.centralUser?.user_hash
        },

        twoFactorActivated(state: AuthState) {
            return state.centralUser?.two_factor_activated
        },

        expiredSubscription(state: AuthState) {
            return Boolean(state.company?.expired_subscription)
        },
    },

    actions: {
        authTenant(
            { commit }: { commit: (...args: any[]) => void },
            {
                central_access_token,
                tenant_access_token,
                tenant_id,
                user,
                company,
                permissions,
            }: {
                central_access_token: string | null
                tenant_access_token: string | null
                tenant_id: string | null
                user: User | null
                company: Company | null
                permissions: string[]
            }
        ) {
            commit('setUser', user)
            commit('setCompany', company)
            commit('setPermissions', permissions)
            commit('setTenantToken', tenant_access_token)
            commit('setCentralToken', central_access_token)
            commit('setTenantId', tenant_id)
        },

        loginCentral(
            { commit }: { commit: (...args: any[]) => void },
            {
                central_access_token,
                centralUser,
                tenants,
            }: {
                central_access_token: string | null
                centralUser: User | null
                tenants: Tenant[] | null
            }
        ) {
            cookie.setItem(STORAGE.CENTRAL_ACCESS_TOKEN, central_access_token)

            commit('setCentralUser', centralUser)
            commit('setTenants', tenants)
            commit('setCentralToken', central_access_token)
        },

        loginTenant(
            { dispatch }: { dispatch: (...args: any[]) => void },
            {
                central_access_token,
                tenant_access_token,
                tenant_id,
                user,
                expired_in,
                company,
            }: {
                central_access_token: string | null
                tenant_access_token: string | null
                tenant_id: string | null
                user: User | null
                expired_in: string | null
                company: Company | null
            }
        ) {
            let opts = {}
            if (isEmpty(expired_in) && isInteger(expired_in)) {
                opts = {
                    ...opts,
                    expires: expired_in,
                }
            }

            cookie.setItem(
                STORAGE.CENTRAL_ACCESS_TOKEN,
                central_access_token,
                opts
            )
            cookie.setItem(
                STORAGE.TENANT_ACCESS_TOKEN,
                tenant_access_token,
                opts
            )

            cookie.setItem(STORAGE.TENANT_ID, tenant_id, opts)

            dispatch('authTenant', {
                central_access_token,
                tenant_access_token,
                tenant_id,
                user,
                company,
                permissions:
                    user?.roles?.[0]?.permissions?.map(
                        (permission: { name: string }) => permission.name
                    ) ?? [],
            })
        },

        logout({ commit }: { commit: (...args: any[]) => void }) {
            posthog.reset()

            cookie.removeItem(STORAGE.TENANT_ACCESS_TOKEN)
            cookie.removeItem(STORAGE.CENTRAL_ACCESS_TOKEN)
            cookie.removeItem(STORAGE.TENANT_ID)

            commit('setUser', null)
            commit('setCompany', null)
            commit('setCentralUser', null)
            commit('setTenantToken', null)
            commit('setTenants', null)
            commit('setCentralToken', null)
            commit('setTenantId', null)
        },
    },
}
